@import "../variables.scss";

.svc-flex-container {
  display: flex;
}

.svc-flex-row {
  display: flex;
  flex-direction: row;
  background: $background-dim;
}

.svc-full-container {
  height: 100%;
  width: 100%;
}

.svc-flex-row__element--growing {
  flex: 1;
  overflow: auto;
}

.svc-flex-column {
  display: flex;
  flex-direction: column;
}

.svc-top-bar {
  display: flex;
  background: $background;
  box-sizing: border-box;
  box-shadow: inset 0px -1px 0px $border;

  .svc-toolbar-wrapper {
    flex: 0 0 auto;
    display: flex;

    .sv-action-bar {
      justify-content: flex-end;

      .sv-action-bar-separator {
        height: calcSize(4);
      }
    }
  }
}
.svc-footer-bar {
  .svc-toolbar-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: calcSize(6);

    .sv-action-bar {
      justify-content: center;
      padding: 0;
      width: 100%;

      height: calc(6 * #{$base-unit});
      background: $background;
      border-top: 1px solid $border;
    }
  }
}