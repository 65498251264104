/* Put custom css here
survey-creator-core.css and defaultV2.css are copies form node_modules and can be editted as well */

/*input,
textarea {
    user-select: text !important;
    -webkit-user-select: text !important;
}*/

.sv-string-editor {
    user-select: text !important;
    -webkit-user-select: text !important;
}

.svc-designer__placeholder-container {
    text-align: center !important;
}

.svc-tab-designer_content {
    margin-right: 0 !important;
}

.svc-page__question-type-selector-icon {
    line-height: initial;
}

.svc-page-navigator__navigator-icon {
    padding: 0 !important;
}

/*Items edit cards*/
.sd-clearfix>div {
    min-width: initial !important;
}

.svc-item-value-controls {
    /*justify-content: flex-start !important;*/
}

.sv-action--first {
    min-width: auto !important;
}

/*Logic*/
div.svc-logic-operator {
    height: auto !important;
}

.svc-logic-tab__content-empty {
    text-align: center !important;
}

/*LOGO img*/
.svc-logo-image {
    display: none !important;
}

/*Xs devices*/
@media (max-width: 330px) {
    .svc-creator__banner {
        bottom: -80px !important;
    }
}

@media (max-width: 478px) {
    .svc-toolbox--compact {
        display: none !important;
    }

    .sd-container-modern__title {
        flex-direction: column-reverse !important;
    }

    /*Items edit cards*/
    .svc-rating-question-content {
        display: flex !important;
        flex-direction: column-reverse !important;
    }

    .sd-rating {
        margin: 0 !important;
        width: 100% !important;
    }

    .svc-item-value-controls {
        position: initial !important;
        justify-content: flex-end !important;
    }

    .sd-container-modern {
        min-width: 100% !important;
    }

    .svc-side-bar__container {
        min-width: auto !important;
    }

    .svd-grid-expand {
        display: none;
    }

    .sv-action__content {
        padding-right: 0 !important;
    }

    /*footer*/

    .svc-toolbar-wrapper {
        display: block !important;
        position: absolute !important;
        left: 0 !important;
        right: 0 !important;
        bottom: -48px !important;
        height: 48px !important;
    }

    .sv-action-bar {
        top: 4px;
        justify-content: center !important;
        overflow-x: auto !important;
    }

    .sv-action-bar-item {
        /*padding: 0 auto !important;*/
    }

    .svc-creator__banner {
        bottom: -80px !important;
    }

    .svc-text--normal {
        font-size: 0.85rem !important;
    }

    /*Preview*/
    .sd-body__page {
        min-width: auto !important;
    }

    .sd-body--empty {
        font-size: 0.85rem !important;
    }

    /* Logic**/
    .sl-table {
        width: 50%;
        margin: 0 0 0 10px !important;
    }

    .svc-logic-tab__content-empty {
        font-size: 0.85rem !important;
    }

    .svc-logic-operator--action {
        width: 90%;
    }
}


/*Panel*/

.svc-side-bar__container {
    width: auto !important;
}

@media (min-width: 479px) and (max-width: 768px) {
    /*.sd-clearfix > div {
            min-width: 300px !important;
        }*/
}