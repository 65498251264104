.underline {
    position: relative;
}
.underline::after {
    content: "";
    width: 125%;
    height: 28px;
    position: absolute;
    left: -15px;
    bottom: -15px;
    background-image: url("../../assets/underline.png");
    background-repeat: no-repeat;
    background-size: 100% 25px;
}