@import "../../variables.scss";

.svc-logo-image {
  position: relative;
  justify-content: flex-end;
  margin-top: calcSize(2);
  .svc-image-item-value-controls {
    display: block;
  }
}

.svc-logo-image-placeholder {
  font-size: calcSize(4);
  color: $foreground-light;
  opacity: 0.25;
  cursor: pointer;
  white-space: nowrap;
  word-break: keep-all;
}