.spg-comment {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  background: $background;
  box-sizing: border-box;
  width: 100%;
  height: calcSize(9);
  border: 1px solid $border-inside;
  padding: calcSize(1.5) calcSize(2);
  outline: none;
  color: $foreground;
  max-width: 100%;
  min-width: calcSize(6);
  min-height: calcSize(6);
  font-family: $font-family;
  font-size: calcSize(2);
  line-height: calcSize(3);
}

.spg-comment::placeholder {
  color: $foreground-light;
}

.spg-comment:focus {
  border: 2px solid $primary;
}

.spg-comment:disabled,
.spg-comment:disabled::placeholder {
  color: $foreground-disabled;
}
