@import "../../variables.scss";

.svc-embed-tab__content {
  padding: calcSize(2);
  font-family: $font-family;
  
  .spg-row {
    display: flex;

    .spg-question__content  {
      .spg-comment:disabled,
      .spg-comment:disabled::placeholder {
          color: $foreground;
      }
    }
  }
  
  .spg-question__description {
    line-height: calcSize(3);
    font-size: calcSize(2);
    color: $foreground;
    padding: calcSize(2) 0 calcSize(2) calcSize(2);
  }
}

.sv-question-embed__title {
  display: none;
}