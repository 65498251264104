.template-action-card {
    position: relative;
    transform: translateY(200%);
    transition: transform 300ms ease-in-out;
}

.template-card:hover .template-action-card {
    transform: translateY(0);
}

.template-content-card {
    position: relative;
    transform: scale(0.8);
    transition: transform 300ms ease-in-out;
}

.template-card:hover .template-content-card {
    transform: scale(1);
}

.card-action-bottom {
    position: relative !important;
    top: -63px;
    padding: 10px 0;
}

@media (max-width: 768px) {
    .template-action-card {
        transform: translateY(0);
    }
}