@import "../variables.scss";

.svc-string-editor {
  position: static;
  .sv-string-editor {
    display: inline-block;
    position: relative;
    outline: none;
    white-space: normal;
    word-break: break-word;
    z-index: 12;
    &[aria-placeholder]:empty:before {
      content: attr(aria-placeholder);
      color: $foreground-light;
    }
  }
  &.svc-string-editor--hidden {
    display: none;
  }
}

.svc-string-editor__content {
  display: inline-block;
  position: relative;
  z-index: 10;
}

.svc-string-editor__border {
  display: none;
  position: absolute;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: right;
  line-height: 0px;
}
.svc-string-editor__error {
  margin-left: calcSize(1);
  line-height: calcSize(4);
  font-size: calcSize(2);
  color: red;
}

.svc-string-editor__controls {
  position: absolute;
  left: 100%;
  height: 100%;
  width: calcSize(2);
  top: 0;
  padding-left: calcSize(2);
  z-index: 12;
  display: none;
  align-items: center;
  justify-content: center;
  line-height: 0px;
}

.svc-string-editor__button {
  display: none;
  position: relative;
  use {
    fill: $primary;
  }
}

.svc-string-editor__button--edit {
  height: calcSize(2);
  width: calcSize(2);
  padding: calcSize(1);
  .sv-svg-icon {
    vertical-align: bottom;
  }
  &.sv-svg-icon {
    overflow: visible;
  }
}

.svc-string-editor:hover:not(:focus-within) {
  .svc-string-editor__button--edit {
    //display: inline-block;
    display: none;
  }
}

.svc-string-editor:hover:not(.svc-string-editor--readonly):not(:focus-within) {
  color: $foreground;
  .svc-string-editor__border {
    display: flex;
    top: calcSize(0);
    bottom: calcSize(0);
    left: calcSize(-0.5);
    //right: calcSize(-4.5); // if need pencil icon
    right: calcSize(-0.5);
    border: none;
    background-color: $primary-light;
    border-radius: 3px;
  }
}
.svc-string-editor:focus-within {
  color: $foreground;
  .svc-string-editor__border {
    display: flex;
    top: calcSize(-0.5);
    bottom: calcSize(-0.5);
    left: calcSize(-1);
    right: calcSize(-1);
    border: 2px solid $primary;
    border-radius: 5px;
    box-sizing: content-box;
    background-color: $background;
    box-shadow: 0px 2px 6px $shadow-medium;
  }
}

.sd-rating {
  .sd-rating__item:focus-within {
    border: calcSize(0.25) solid $primary;
  }
  &:not(.sd-rating__min-text) {
    .svc-string-editor:focus-within {
      .svc-string-editor__border {
        display: none;
      }
    }
  }
}
.sd-table__cell--header {
  .svc-matrix-cell:not(:focus):focus-within {
    .svc-matrix-cell--selected {
      border: calcSize(0.25) solid $primary;
      border-radius: calcSize(0.5);
      display: block;
    }
  }
  .svc-string-editor:focus-within {
    .svc-string-editor__border {
      display: none;
    }
  }
}
.sd-item__control-label {
  .svc-string-editor:focus-within {
    .svc-string-editor__border {
      left: calcSize(-0.7);
      right: calcSize(-0.7);
    }
  }
}

// .sv-question__header,
// .sv-title {
//   position: relative;
// }
