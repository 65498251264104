@import "../../variables.scss";

svc-tab-designer {
  width: 100%;
  height: 100%;
  background: $background-dim;
}

.svc-tab-designer {
  display: flex;
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
}

.svc-tab-designer--with-place-holder .svc-tab-designer_content {
  display: flex;
  justify-content: center;
}

.svc-tab-designer {
  .svc-tab-designer_content {
    width: 100%;
  }
  .sd-container-modern {
    min-width: calcSize(70);
    width: 100%;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    &.sd-container-modern--static {
      max-width: calcSize(84);
    }
    &.sd-container-modern--responsive {
      max-width: initial;
      padding: 0 calcSize(1);
    }
  }
  .sd-question.sd-question--image {
    width: 100%;
  }

  .sd-progress, .sd-progress__bar {
    background-color: transparent;
  }
}
.svc-designer-header {
  border-bottom: 2px solid transparent;
}

.svc-designer__placeholder-container {
  position: absolute;
  top: 35%;
}

.svc-designer-placeholder-page {
  margin-top: calcSize(2);
}
.svc-designer-placeholder-page .svc-page__add-new-question {
  width: calcSize(33);
  margin: auto;
}
.svc-designer-placeholder-page .svc-row--ghost {
  display: none;
}
