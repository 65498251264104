.spg-table {
  width: 100%;
  background-color: $background;
  border: 1px solid $border;
  border-collapse: collapse;
  tr {
    border-bottom: 1px solid $border;
  }
}
.spg-table__cell {
  padding: 0;
  .spg-checkbox {
    margin: 0 calcSize(2);
  }
}

.spg-table__cell--detail-panel {
  .spg-panel__content {
    box-shadow: none;
  }
  background: $background-dim;
}
.spg-table__cell:not(.spg-table__cell--detail-panel) .spg-text {
  appearance: none;
  line-height: calcSize(3);
  font-size: calcSize(2);
  border: none;
  background-color: transparent;
  padding: calcSize(1.5) 0;
  padding-right: calcSize(2);
  &[type="color"] {
    padding-right: 0;
  }
}
.spg-table__cell:not(.spg-table__cell--detail-panel):first-of-type .spg-text {
  padding-left: calcSize(2);
}
.spg-table__cell--actions .spg-action-bar {
  opacity: 0.5;
}
.spg-table__cell--actions:first-child {
  width: calcSize(6);
}
.spg-table__cell--actions > .spg-matrixdynamic__drag-element {
  display: inline-block;
  margin-top: calcSize(0.5);
  cursor: move;
}
.spg-table__cell--header {
  font-size: calcSize(1.5);
  font-weight: normal;
  color: $foreground-light;
  line-height: calcSize(3);
  background: $background-dim;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100px;  
}
